:root {
--f7-block-margin-vertical:10px;
--f7-list-margin-vertical:16px;
--f7-card-margin-horizontal: 0px;
--f7-card-margin-vertical: 8px;
}

::-webkit-scrollbar {
    width: 8px;
}
  
/* Track */
::-webkit-scrollbar-track {
background: #2a2a2a;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #555;
}

.paper-short-description p {
    font-size:14px;
    margin:0;
}

.hide {
    display:none;
}

.show {
    display:block;
}

.border {
    filter: 
        drop-shadow(0 -1px 0 gray)
        drop-shadow(0 1px 0 gray)
        drop-shadow(-1px 0 0 gray)
        drop-shadow(1px 0 0 gray);
}